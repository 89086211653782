<template>
  <div class="eqmlog">
    <el-row :gutter="0" class="search" align="middle" type="flex">
      <el-col :span="2" class="search_item">设备编号:</el-col>
      <el-col :span="3" class="search_item">
        <el-select
          v-model="company_id"
          clearable
          placeholder="请选择公司"
          @change="userselecteqm($event)"
        >
          <el-option
            v-for="item in companyoptions"
            :key="item.company_id"
            :label="item.company_name"
            :value="item.company_id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="1" class="search_item">时间:</el-col>
      <el-col :span="6" class="search_item">
        <el-date-picker
          v-model="value2"
       
           value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
        
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker
      ></el-col>

      <el-col :span="2" :offset="10" class="search_item"
        ><el-button
          type="primary"
          style="backgroundcolor: #0076f6"
          @click="orderseach()"
          >查询</el-button
        ></el-col
      >
    </el-row>
    <div>
      <el-table
        v-loading="loading"
        element-loading-text="正在刷新数据"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :data="tableData"
        border
        align="center"
        stripe
        style="color: #161718"
        max-height="710"
      >
        <el-table-column prop="company" label="公司" align="center">
        </el-table-column>
        <el-table-column prop="order" label="订单号" align="center">
        </el-table-column>

        <el-table-column prop="iccids" label="订单设备" align="center" >
        </el-table-column>
        <el-table-column prop="years" label="续费时长" align="center" width='100'>
        </el-table-column>
        <el-table-column prop="payment" label="金额" align="center" width='100'>
          <template slot-scope="scope">
         
          <span >{{scope.row.payment}}元</span>
        </template>
        </el-table-column>
        <el-table-column prop="pay_reault" label="充值状态" align="center" width='100'>
           <template slot-scope="scope">
          <span style="color: #33cc66" v-if="scope.row.pay_reault == 'SUCCESS'">支付成功</span>
          <span style="color: red" v-else>支付失败</span>
        </template>
        </el-table-column>
        <el-table-column prop="time" label="充值时间" align="center">
        </el-table-column>
      </el-table>
    </div>
    <el-row class="paging" align="middle" type="flex" justify="end">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="pagesize"
        layout="prev,total, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { orderinfo, orderpages, orderseach } from "../../api/orderform.js";
export default {
  components: {},
  data() {
    return {
      loading: false,
      idcode: this.$route.params.code,
      companyid: this.$route.params.companyid,
      selectcompanyid: null,
      companyoptions: [
        {
          company_id: "",
          company_name: "",
        },
      ],
      company_id: "",
      /**级联选择设备 */
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: "",
      value2: "",
      tableData: [],
      currentPage3: 1,
      pagesize: 12,
      total: 32,
    };
  },
  created() {
    this.userRoleId = this.$cookies.get("userRoleId");
  },
  mounted() {
    this.loading = true;
    this.getorderform();
  },
  methods: {
    /**获取设备日志信息 */
    getorderform() {
      orderinfo()
        .then((res) => {
          console.log(res);
          console.log("这是订单信息");
          this.loading = false;
          this.companyoptions = res.data.companies;

          this.tableData = res.data.orders;
          this.pagesize = res.data.page_size;
          this.total = res.data.total_pages;
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
    /**级联选择设备
     */
    userselecteqm(e) {
      console.log(e, "用户用户");
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      if (this.value2) {
        orderseach(this.company_id, this.value2[0], this.value2[1], val)
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.orders;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          });
      } else {
        orderseach(this.company_id, "", "", val)
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.orders;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          });
      }
    },
    orderseach() {
       this.currentPage3 = 1;
      // console.log("查询时间");
      // console.log(this.company_id, this.value2[0], this.value2[1],'查询条件')
      if (this.value2) {
        orderseach(this.company_id, this.value2[0], this.value2[1], "")
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.orders;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          });
      } else {
        orderseach(this.company_id, "", "", "")
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.orders;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          });
      }
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 1920px) {
  .eqmlog {
    /* background-color: darkblue; */
    padding: 1.25rem 1.875rem 0 1.875rem;
  }
  .paging {
   width: 70%;
    float: right;
    /* background-color: chocolate; */
    text-align: end;
    margin-top: 1.25rem;
  }
  .search {
    padding: 0.5rem 0.625rem 0.5rem 0.625rem;
    font-size: 0.875rem;
    /* border: 0.0625rem solid black; */
    background-color: #ebeff5;
    border-radius: 0.625rem;

    text-align: center;
  }
  .search_item {
    /* background-color: darkorchid; */
    text-align: center;
  }
  .el-table {
    margin-top: 1.25rem;
  }
}
@media only screen and (max-width: 1080px) {
  .el-date-editor--datetimerange.el-input,
  .el-date-editor--datetimerange.el-input__inner {
    width: 13rem !important;
  }
  .eqmlog {
    /* background-color: darkblue; */
    padding: 0.625rem 0.625rem 0 0.625rem;
  }
  .paging {
   width: 70%;
    float: right;
    /* background-color: chocolate; */
    text-align: end;
    margin-top: 0.625rem;
  }
  .search {
    padding: 0.25rem 0.3125rem 0.25rem 0.3125rem;
    font-size: 0.875rem;
    /* border: 0.0625rem solid black; */
    background-color: #ebeff5;
    border-radius: 0.625rem;

    text-align: center;
  }
  .search_item {
    /* background-color: darkorchid; */
    text-align: center;
  }
  .el-table {
    margin-top: 0.625rem;
  }
}
</style>